(function($, window, document, undefined) {
  // base https://github.com/NightOwl888/jquery.dirtyforms.dialogs.bootstrap.dist

  $.DirtyForms.dialog = {
      title: 'Leave with unsaved changes?',
      proceedButtonClass: 'dirty-proceed',
      proceedButtonText: 'Leave This Page',
      stayButtonClass: 'dirty-stay',
      stayButtonText: 'Stay Here',
      dialogID: 'dirty-dialog',
      titleID: 'dirty-title',
      messageClass: 'dirty-message',
      preMessageText: '',
      postMessageText: '',
      replaceText: true,

      open: function (choice, message) {
          let $dialog = $('#' + this.dialogID);

          if ($dialog.length === 0) {
              $dialog =
                  $('<div id="' + this.dialogID + '" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="' + this.titleID + '" data-bs-backdrop="static">' +
                      '<div class="modal-dialog" role="document">' +
                          '<div class="modal-content">' +
                              '<div class="modal-header text-bg-danger">' +
                                  '<h5 class="modal-title" id="' + this.titleID + '"></h5>' +
                                  '<button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>' + 
                              '</div>' +
                              '<div class="modal-body ' + this.messageClass + '"></div>' +
                              '<div class="modal-footer">' +
                                  '<button type="button" class="' + this.proceedButtonClass + ' btn btn-danger" data-bs-dismiss="modal"></button>' +
                                  '<button type="button" class="' + this.stayButtonClass + ' btn btn-outline-danger" data-bs-dismiss="modal"></button>' +
                              '</div>' +
                          '</div>' +
                      '</div>' +
                  '</div>');

              $('body').append($dialog).data('df-dialog-appended', true);
          }

          if (this.replaceText) {
              $dialog.find('#' + this.titleID).html(this.title);
              $dialog.find('.' + this.messageClass).html(this.preMessageText + message + this.postMessageText);
              $dialog.find('.' + this.proceedButtonClass).html(this.proceedButtonText);
              $dialog.find('.' + this.stayButtonClass).html(this.stayButtonText);
          }

          choice.bindEscKey = false;

          const onContinueClick = function () {
              choice.proceed = $.DirtyForms.choiceContinue = true;
          };
          const onHidden = function (e) {
            const commit = choice.isDF1 ? $.DirtyForms.choiceCommit : choice.commit;
              commit(e);
              if ($('body').data('df-dialog-appended') === true) {
                  $dialog.remove();
              }
          };

          $dialog.find('.' + this.proceedButtonClass).off('click', onContinueClick).on('click', onContinueClick);
          $dialog.off('hidden.bs.modal', onHidden).on('hidden.bs.modal', onHidden);

          $dialog.modal('show');
      },
  };
})(jQuery, window, document);

(function ($) {
  $(function () {
    $(document).on('bind.dirtyforms', function(ev, events) {
      const originalOnAnchorClick = events.onAnchorClick;
      const originalOnRefireAnchorClick = events.onRefireAnchorClick;

      const skipAnchorOnlyLink = function(callback) {
        return function(event) {
          const $target = $(event.target).closest('a');
          if ($target.attr('href') && $target.attr('href').startsWith('#')) {
            return;
          }
          callback(event);
        };
      };

      events.onAnchorClick = skipAnchorOnlyLink(originalOnAnchorClick);
      events.onRefireAnchorClick = skipAnchorOnlyLink(originalOnRefireAnchorClick);
    });

    if ($('form.alert-unsaved-changes').length) {
      $('form.alert-unsaved-changes').dirtyForms({
        message: 'You have unsaved changes on this page. If you leave, your changes will be lost.',
      });
    }
  });
})(jQuery, window);
