(function($) {
  $(document).ready(function() {
    $(document).on('click', '[data-dismissible-alert] .btn-close', function(event) {
      var alert = $(event.target)
          .closest('[data-dismissible-alert]')
          .data('dismissible-alert');
      $.post('/account/alerts/' + alert + '/');
    });
  });
})(jQuery);
