import 'bootstrap';

window.Sortable = Sortable
window.jQuery = jQuery;
window.$ = $;
window.typeahead = typeahead

import 'jquery.dirtyforms/jquery.dirtyforms.js'
import 'django-superformset/src/django-superformset.js';

import './components/alerts.js';
import './components/datepicker.js';
import './components/file_browser.js';
import './components/form.js';
import './components/formset.js';
import './components/image_with_thumbnails.js';
import './components/tooltips.js';
import './components/company_search_component.js';
import './components/search_bar.js';
import './components/live_toast.js';
import './components/custom_select_multiple.js';
import './components/alert_unsaved_changes.js';
